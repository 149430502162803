<style src="../../assets/css/inputeo.css" scoped></style>
<style scoped>
    label {
        cursor: pointer;
        padding-bottom: 15px;
        padding-top: 10px;
        margin-left: 0px;
    }

    input {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    .invalid-mark {
        left: 0px;
    }
</style>

<template>
    <div class="inputeo" :class="{ 'inputeo-icon': $slots.iconBefore }">
        <div v-if="displayError && error" class="invalid-mark">
            <div class="invalid-mark-message-container">
                <div class="invalid-mark-message">{{ error }}</div>
            </div>
        </div>
        <div class="fa-icon" v-if="$slots.iconBefore">
            <slot name="iconBefore"></slot>
        </div>
        <label v-if="modelValue" :for="id">{{ modelValue }}</label>
        <input
            v-bind="$attrs"
            type="file"
            :accept="accept"
            :tabIndex="tabIndex"
            @input="onInput"
            :id="id"
            :disabled="disabled"
            :class="disabled ? 'disabled' : ''"
        />
    </div>
</template>

<script>
    export default {
        name: 'BaseFile',
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            displayError: {
                type: Boolean,
                default: false
            },
            error: {
                type: String,
                default: ''
            },
            tabIndex: {
                type: String,
                default: '1'
            },
            files: {
                type: [Array, FileList],
                default: null
            },
            required: {
                type: Boolean,
                default: false
            },
            accept: {
                type: String,
                default: '*'
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            modelValue: { 
                handler() {
                    this.checkField();
                }
            }
        },
        data() {
            return {
                id: null,
                initialModelValue: this.modelValue || 'Document'
            }
        }, 
        mounted() {
            this.id = Math.floor(Math.random() * Date.now());
            this.checkField();
        },
        methods: {
            checkField() {
                let error = null;

                if (this.required === true && this.files != null && this.files.length === 0) {
                    error = `Ce champ est requis`;
                }

                // On émet l'événement pour spécifier qu'il y a un changement
                this.$emit('onChange', {
                    name: this.name,
                    value: this.files,
                    error: {
                        type: 'validator',
                        message: error
                    }
                });
            },
            onInput(event) {
                let value = event.target.value;

                if (value === null || value.trim().length === 0) {
                    this.$emit('update:modelValue', this.initialModelValue);
                    this.$emit('update:files', []);
                } else {
                    this.$emit('update:modelValue', event.target.value.substr(event.target.value.lastIndexOf('\\') + 1));
                    this.$emit('update:files', event.target.files);
                }

                this.$nextTick(this.checkField);
            }
        }
    }
</script>