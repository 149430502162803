<style scoped>
    .topbar-right-button {
        font-weight: 500;
        border: none;
        background: linear-gradient(90deg, #1e73be, #00a1cf);
        color: white;
        font-size: 13px;
        padding: 9px 15px;
        border-radius: 4px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.15s ease;
        position: fixed;
        right: 30px;
        top: 16px;
        z-index: 22;
    }

    .topbar-right-button .fa-icon {
        width: 13px;
        height: 13px;
        color: white;
        margin-right: 8px;
    }

    @media screen and (max-width: 1200px) {
        .topbar-right-button {
            max-width: 180px;
            top: 8px;
        }
    }
</style>

<template>
    <BaseButton class="topbar-right-button" v-show="buttonText.length > 0" @click="$emit('onTopbarButtonClicked')" :buttonText="buttonText">
        <template v-slot:iconBefore>
            <slot name="iconBefore"></slot>
        </template>
    </BaseButton>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton'

    export default {
        name: 'ButtonTopbar',
        components: {
            BaseButton
        },
        props: { 
            buttonText: {
                type: String,
                default: ''
            }
        }
    }
</script>