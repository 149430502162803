<style scoped>
</style>

<template>
    <ButtonTopbar buttonText="Créer un document" @onTopbarButtonClicked="displayCreateDocumentModal">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
    </ButtonTopbar>
    <Aggrid
        :apiParams="filter"
        apiRoute="document/list"
        :columnDefs="columnDefs" 
        :context="context"
        :frameworkComponents="frameworkComponents"
        aggridHeightSubstract="100px"
    >
        <template v-slot:filter>
            <div>
                <BaseInput v-model="search" label="Rechercher un document" type="text" name="search" @onChange="onSearchInputChange">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </template>
                </BaseInput>
            </div>
        </template>
    </Aggrid>
    <Modal v-show="displayModalCreateOrEditDocument === true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', createOrEditMode === 'create' ? 'plus' : 'edit']" />
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrEditMode === 'create'">CRÉER UN DOCUMENT</div>
            <div v-show="createOrEditMode === 'edit'">MODIFIER UN DOCUMENT</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseInput
                        v-model="document.name"
                        label="Nom"
                        type="text"
                        name="name"
                        validator="text"
                        :error="formErrors?.name?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                </div>
                <div>
                    <BaseInput 
                        v-model="document.description"
                        label="Description"
                        type="text"
                        name="description"
                        validator="text"
                        :error="formErrors?.description?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                </div>
                <div>
                    <BaseFile 
                        v-model="document.path"
                        name="path"
                        :files="document.file"
                        v-on:update:files="document.file = $event"
                        :error="formErrors?.path?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                        :required="true"
                        accept="application/pdf"
                    >
                        <template v-slot:iconBefore>
                            <font-awesome-icon :icon="['fas', 'file-pdf']" />
                        </template>
                    </BaseFile>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Dialog ref="dialog" />
</template>

<script>
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar'
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import BaseFile from '../../components/Base/BaseFile'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import Modal from '../../components/App/Modal'
    import Dialog from '../../components/App/Dialog'
    import ListDocumentsActionButtonsRenderer from './ListDocumentsActionButtonsRenderer.vue'
    import axios from 'axios'
    import config from "../../config"

    export default {
        name: 'Disciplines',
        components: {
            ButtonTopbar,
            BaseButton,
            BaseInput,
            BaseFile,
            ListDocumentsActionButtonsRenderer,
            Aggrid,
            Modal,
            Dialog
        },
        data() {
            return {
                columnDefs: [
                    { field: 'name', headerName : `Nom`, flex: 1, lockPosition: true },
                    { field: 'description', headerName : `Description`, flex: 1, lockPosition: true },
                    { headerName : '', width: 200, cellRenderer: 'ListDocumentsActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListDocumentsActionButtonsRenderer: ListDocumentsActionButtonsRenderer
                },
                context: null,
                displayModalCreateOrEditDocument: false,
                createOrEditMode: null,
                search: '',
                searchTimeout: null,
                document: null,
                filter: {
                    deleted: false,
                    search: ''
                },
                displayError: false,
                formErrors: {}
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetDocument();
        },
        methods: {
            closeModal() {
                this.resetDocument();
                this.displayModalCreateOrEditDocument = false;
                this.displayError = false;
            },
            submitModal() {
                if (this.createOrEditMode === 'create') {
                    this.createDocument();
                } else if (this.createOrEditMode === 'edit') {
                    this.editDocument();
                }
            },
            displayCreateDocumentModal() {
                this.createOrEditMode = 'create';
                this.resetDocument();
                this.displayModalCreateOrEditDocument = true;
            },
            displayEditDocumentModal(document) {
                this.createOrEditMode = 'edit';
                this.document = JSON.parse(JSON.stringify(document));
                this.document.path = document.path.substr(document.path.lastIndexOf('/') + 1);
                this.displayModalCreateOrEditDocument = true;
            },
            openDocumentLink(document) {
                window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${document.path}`, '_blank').focus();
            },
            resetDocument() {
                this.document = {
                    name: null,
                    description: null,
                    path: 'Document',
                    file: []
                };
            },
            formateDocument(document) {
                var formData = new FormData();
                formData.append('id', document.id);
                formData.append('name', document.name);
                formData.append('description', document.description);
                if(document.file && document.file.length > 0) {
                    formData.append('file', document.file[0]);
                }
                return formData;
            },
            createDocument() {
                if (!this.checkForm()) return;

                axios
                .post('document/create', this.formateDocument(this.document), { 
                    toastSuccessMessage: `Document créé`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModal();
                    this.emitter.emit('ag-grid-reload')
                })
                .catch(() => {});
            },
            editDocument() {
                if (!this.checkForm()) return;

                axios
                .put('document/update', this.formateDocument(this.document), { 
                    toastSuccessMessage: `Modification effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModal();
                    this.emitter.emit('ag-grid-reload')
                })
                .catch(() => {});
            },
            deleteOrEnableDocument(document) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir ${document.deleted ? 'ré-activer' : 'supprimer'} ce document ?`
                }).then(() => {
                    axios
                    .put('document/changeDeletedDocument/' + document.id, {
                        deleted: !document.deleted
                    }, { 
                        toastSuccessMessage: `Modification effectuée`, 
                        toastError: true, 
                        showPreloader: true 
                    })
                    .then(() => {
                        this.emitter.emit('ag-grid-reload')
                    });
                })
                .catch(() => {});
            },
            onFormInputChange(input) {
                if (input.error?.message !== null) {
                    this.formErrors[input.name] = input;
                } else {
                    delete this.formErrors[input.name];
                }
            },
            checkForm() {
                this.displayError = true;

                return Object.keys(this.formErrors).length === 0;
            },
            onSearchInputChange(input) {
                if(this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }
                
                this.searchTimeout = setTimeout(() => {
                    this.filter.search = input.value
                },250)
            }
        }
    }
</script>