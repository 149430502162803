<template>
    <div class="container-action-button">
        <BaseButton @click="onDeleteOrEnableDocument" buttonText="" title="Supprimer">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash']" />
            </template>
        </BaseButton>
        <BaseButton @click="onEditDocument" buttonText="" title="Modifier">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'pen']" />
            </template>
        </BaseButton>
        <BaseButton @click="onOpenDocumentLink" buttonText="" title="Voir">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-pdf']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListDocumentsActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onDeleteOrEnableDocument() {
                this.params.context.componentParent.deleteOrEnableDocument(this.params.data);
            },
            onEditDocument() {
                this.params.context.componentParent.displayEditDocumentModal(this.params.data);
            },
            onOpenDocumentLink() {
                this.params.context.componentParent.openDocumentLink(this.params.data);
            }
        }
    }        
</script>